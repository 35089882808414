import React, { useEffect } from "react";
// import ContactUs from "../components/homepage/contactUs/contactUs";
// import Homesec4 from "../components/homepage/h4LinkManagement";
// import Homesec5 from "../components/homepage/h5CustomLink";
import Homesec1 from "../components/homepage/homesec1";
// import Homesec2 from "../components/homepage/homesec2";
// import Homesec3 from "../components/homepage/homesec3";
// import Pricing from "../components/homepage/Price";
import { useState } from "react";
import loadable from "@loadable/component";
// import Captcha from "../components/homepage/Captcha";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Enterprise from "../components/homepage/Enterprise-Plan/Enterprise";
import Faq from "../components/landingPage/Faq";
const Reviews = loadable(() => import("../components/landingPage/Reviews"));

const Homesec2 = loadable(() => import("../components/homepage/homesec2"));
const Homesec3 = loadable(() => import("../components/homepage/homesec3"));
const Homesec4 = loadable(() =>
  import("../components/homepage/h4LinkManagement")
);
const Homesec5 = loadable(() => import("../components/homepage/h5CustomLink"));
const Pricing = loadable(() => import("../components/homepage/Price"));
const ContactUs = loadable(() =>
  import("../components/homepage/contactUs/contactUs")
);

const IndexPage = () => {
  const [marginValue] = useState(true);
  const [plan, setPlan] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const listenToScroll = () => {
    setScrolled(true);
  };
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <Layout styleOne>
      <Seo
        slug="/"
        title="Online Link Shortener and Generator | Short My Link - Teenyfy"
        description="Teenyfy.com is best tool to create short link, create tiny url and url shortener online. Easily shorten long URLs, create tiny URLs, and simplify sharable link. "
      />
      <Homesec1 />
      {/* <Captcha /> */}
      <Homesec2 />
      {scrolled && (
        <>
          <Homesec3 />
          <Homesec4 />
          <Homesec5 />
          <Pricing plan={plan} setPlan={setPlan} />
          <Enterprise />
          <Reviews padding="py-12" />
          <Faq titleSize="text-4xl" />
          <ContactUs marginValue={marginValue} />
        </>
      )}
    </Layout>
  );
};

export default IndexPage;
