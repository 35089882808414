import React from "react";
import Check from "../../images/tick-in-box.svg";
import { Link } from "gatsby";
import Lottie from "react-lottie-player";
import lottieJson from "../../images/lotties/tnfy-illustration-lottie1.json";

function Homesec1() {
  return (
    <div className="bg-[#EAFAFA]">
      <main>
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -mx-4 -mb-10 text-center md:text-left">
            <div className="sm:w-1/2 px-4 my-auto">
              <h1>
                <span className="font-[400] text-[48px] text-[#1F1F39] leading-[58px] xs:font-poppins">
                  Make Your Links
                </span>
                <span className="font-[400] text-[48px] text-[#1F1F39] leading-[58px] xs:font-poppins">
                  {" "}
                  More Memorable
                </span>
              </h1>
              <h3 className="text-lg">
                Shorten your urls into branded , readable format with{" "}
                <Link to="/" className="text-dark-green">
                  Teenyfy
                </Link>
              </h3>
              <a
                href="https://app.teenyfy.com/user/register"
                className="hidden md:block  hover:bg-dark-green bg-white w-[180px] h-[54px] px-[30px] text-center mt-8 rounded-[40px] font-[16px] font-[700] leading-[48px] no-underline border-2 border-[#1F1F39]"
              >
                Get Started
              </a>
              <div className="md:flex space-x-2 mt-4 hidden">
                <img src={Check} alt="check" />
                <p>No Credit Card required</p>
              </div>
            </div>
            <div className="sm:w-1/2 px-4 my-auto text-left">
              <Lottie loop animationData={lottieJson} play />
            </div>
            <div className="flex flex-col w-full items-center justify-center">
              <a
                href="https://app.teenyfy.com/user/register"
                className="block md:hidden  hover:bg-dark-green bg-white w-[160px] h-[54px] px-[30px] text-center rounded-[40px] font-[16px] font-[700] leading-[48px] no-underline border-2 border-[#1F1F39] mt-10 mb-5"
              >
                Get Started
              </a>
              <div className="md:hidden flex justify-center space-x-2">
                <img src={Check} alt="check" />
                <p>No Credit Card required</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Homesec1;
