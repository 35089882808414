import React, { useState } from "react";
import Plus from "../../images/plus.svg";
import Minus from "../../images/minus.svg";

function Faq({ titleSize }) {
  const [openFirst, setOpenFirst] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [openThird, setOpenThird] = useState(false);
  const [openFourth, setOpenFourth] = useState(false);
  const [openFifth, setOpenFifth] = useState(false);
  return (
    <div className="bg-[#EAFAFA] py-24 px-4">
      <div className="space-y-12">
        <h2 className={`text-center ${titleSize}`}>
          Frequently Asked Questions
        </h2>
        <div>
          <div className="max-w-4xl mx-auto border border-primary border-b-0 px-6 md:px-12 py-4">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => setOpenFirst(!openFirst)}
            >
              <h3>1. What is a URL shortener?</h3>
              <img
                src={openFirst ? Minus : Plus}
                className="cursor-pointer z-10"
              />
            </div>

            <div className={`text-[#636973] ${openFirst ? "open" : "closed"}`}>
              <p className="py-2">
                In simple words, a URL shortener is also referred to as a link
                shortener. These shorteners seem like a simple tool, but it is a
                service that can have a tremendous impact on your marketing
                outcomes. They work by transforming any lengthy URL into a
                shorter, more readable link. When a user clicks the shortened
                version of the URL, they’re automatically forwarded to the
                destination URL.
              </p>
            </div>
          </div>
          <div className="max-w-4xl mx-auto border border-primary border-b-0 px-6 md:px-12 py-4">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => setOpenSecond(!openSecond)}
            >
              <h3>2. What are the benefits of a short URL?</h3>
              <img src={openSecond ? Minus : Plus} className="cursor-pointer" />
            </div>

            <div className={`text-[#636973] ${openSecond ? "open" : "closed"}`}>
              <p className="py-2">
                It becomes very difficult to remember a long web address,
                especially if it has tons of characters and symbols. Therefore,
                a short URL can make your link more memorable. It not only
                allows people to effortlessly recall and share your link with
                others, but it can also drastically improve traffic to your
                content. On a more practical side, whether you’re looking to
                engage with your customers offline or online - a short URL is
                easier to incorporate into your collateral.
              </p>
            </div>
          </div>
          <div className="max-w-4xl mx-auto border border-primary px-6 md:px-12 py-4">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => setOpenThird(!openThird)}
            >
              <h3>3. What is a custom URL shortener?</h3>
              <img src={openThird ? Minus : Plus} className="cursor-pointer" />
            </div>

            <div className={`text-[#636973] ${openThird ? "open" : "closed"}`}>
              <p className="py-2">
                A custom URL shortener which is sometimes referred to as a
                branded URL shortener, facilitates you brand your links. There
                are countless benefits of branding your short links. Branded
                links maintain goodwill and trust between your audience and your
                business. It drives more clicks, gives your audience a preview
                of where they are being taken and ultimately increases brand
                awareness. A link shortening service is vital to improving
                audience engagement with your communications and it also
                includes custom short URLs. A short URL is good. However, when
                it comes to a custom URL, it works every time.
              </p>
            </div>
          </div>
          <div className="max-w-4xl mx-auto border border-primary border-t-0 px-6 md:px-12 py-4">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => setOpenFourth(!openFourth)}
            >
              <h3>4. Why choose Teenyfy?</h3>
              <img src={openFourth ? Minus : Plus} className="cursor-pointer" />
            </div>

            <div className={`text-[#636973] ${openFourth ? "open" : "closed"}`}>
              <p className="py-2">
                Whether you’re sharing one link or millions, our platform is
                built to help you make every point of connection between your
                content and your audience ignite action. It’s why the brands
                simply love Teenyfy.
              </p>
            </div>
          </div>
          <div className="max-w-4xl mx-auto border border-primary border-t-0 px-6 md:px-12 py-4">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => setOpenFifth(!openFifth)}
            >
              <h3>5. What are the features of Teenyfy URL shortener?</h3>
              <img src={openFifth ? Minus : Plus} className="cursor-pointer" />
            </div>

            <div className={`text-[#636973] ${openFifth ? "open" : "closed"}`}>
              <p className="py-2">
                Fast redirects, Link analytics, Multiple domain names, and
                Custom URL slugs (vanity-URL) are some of the main features of
                Teenyfy URL shortener.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
